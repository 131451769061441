<template>
  <div>
    <page-main back title>
      <el-tabs style="margin-top: -10px;" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="业务明细" name="first">
          <el-form class="search" :model="search">
            <div class="form">
              <el-form-item prop="search">
                <el-date-picker
                  v-model="search.date"
                  class="holder-color"
                  format="yyyy年MM月dd日"
                  value-format="yyyy/MM/dd HH:mm:ss"
                  clearable
                  type="daterange"
                  :picker-options="pickerOptions"
                  :default-time="['00:00:00', '23:59:59']"
                  :editable="false"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="onDateChange"
                  @clear="
                    search.pageNo = 1;
                    getData();
                  "
                />
              </el-form-item>
              <el-form-item v-if="!isMobile">
                <el-button
                  type="text"
                  class="plain-button-colored"
                  @click="onExport"
                >
                  导出为Excel
                </el-button>
              </el-form-item>
            </div>
          </el-form>
          <el-table
            v-loading="loading"
            class="financial-table"
            :data="accountBusinessList"
            :default-sort="{ prop: 'date', order: 'descending' }"
            stripe
            @sort-change="onSortChange"
          >
            <el-table-column
              align="center"
              prop="date"
              label="时间"
              sortable
            />
            <el-table-column align="center" prop="sceneType">
              <!-- eslint-disable-next-line vue/no-unused-vars -->
              <template slot="header" slot-scope="scope">
                <el-select
                  v-model="search.sceneType"
                  class="holder-color"
                  clearable
                  placeholder="类型"
                  @change="
                    () => {
                      search.pageNo = 1;
                      getData();
                    }
                  "
                >
                  <el-option
                    v-for="(item, index) in sceneList"
                    :key="index"
                    :label="item.scene"
                    :value="item.code"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="programName"
              label="委托名称"
            />
            <el-table-column :key="sourceList.length" align="center" prop="summary">
              <template slot="header">
                <el-select
                  v-model="search.sourceType"
                  class="holder-color"
                  clearable
                  placeholder="摘要"
                  @change="
                    () => {
                      search.pageNo = 1;
                      getData();
                    }
                  "
                >
                  <el-option
                    v-for="(item, index) in sourceList"
                    :key="index"
                    :label="item.source"
                    :value="item.type"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="integral" label="积分" />
            <el-table-column align="center" prop="payType" label="收入/支出">
              <template slot="header">
                <el-select
                  v-model="search.changeType"
                  class="holder-color"
                  clearable
                  placeholder="收入/支出"
                  @change="
                    () => {
                      search.pageNo = 1;
                      getData();
                    }
                  "
                >
                  <el-option
                    v-for="(item, index) in amountList"
                    :key="index"
                    :label="item.name"
                    :value="item.type"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="assigner" label="操作人" />
            <el-table-column
              label="所在组织"
              width="120"
              show-overflow-tooltip
              prop="orgName"
              fixed="right"
            />
          </el-table>
          <el-row type="flex" justify="center" class="t-p">
            <el-pagination
              background
              hide-on-single-page
              layout="prev, pager, next"
              :page-size="search.pageSize"
              small
              :current-page="search.pageNo"
              :total="totalCount"
              @current-change="onChange"
            />
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </page-main>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import {
  getSourceList,
  getSceneList,
  getDetailDown
} from '@/services/accountService'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'AccountUserBusiness',
  data() {
    return {
      activeName: 'first',
      totalCount: 0,
      loading: false,
      sceneList: [],
      sourceList: [],
      amountList: [
        {
          name: '收入',
          type: '1'
        },
        {
          name: '支出',
          type: '-1'
        }
      ],
      search: {
        // 表格筛选
        date: [],
        pageNo: 1,
        pageSize: 10,
        sceneType: '', // 类型
        sourceType: '', // 摘要
        changeType: '', // 收支
        isAsc: false,
        startTime: '',
        endTime: ''
      },
      withdrawSearch: {
        // 表格筛选
        date: [],
        pageNo: 1,
        pageSize: 10,
        sceneType: '', // 类型
        sourceType: '76,75,49,54,81,52', // 摘要
        isAsc: false,
        startTime: '',
        endTime: ''
      },
      pickerOptions: {
        disabledDate: current => {
          const currentMin = current < this.$moment('2018-01-01')
          const currentMax = current > this.$moment().endOf('day')
          return currentMax || currentMin
        }
      },
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['accountBusinessList'])
  },
  mounted() {
    this.getData()
    // 查询积分摘要列表
    getSourceList().then(res => {
      const { data } = res
      console.log(data)
      this.sourceList = data
    })
    // 场景列表
    getSceneList().then(res => {
      const { data } = res
      this.sceneList = data
    })
  },
  methods: {
    ...mapActions(['getAccountBusinessList']),
    handleClick() {
      if (this.activeName === 'first') {
        this.getData()
      }
      if (this.activeName === 'second') {
        this.getWithdrawData()
      }
    },
    // 获取积分明细列表数据
    async getData() {
      this.loading = true
      const params = Object.assign({}, this.search)
      delete params.date
      try {
        const res = await this.getAccountBusinessList(params)
        this.loading = false
        this.totalCount = res.data.totalCount
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message.error(error?.message)
      }
    },
    // 表格排序
    onSortChange(arg) {
      if (arg.prop === 'date') {
        this.search.isAsc = arg.order === 'ascending'
      }
      this.getData()
    },
    onChange(page) {
      this.search.pageNo = page
      this.getData()
    },
    // 导出数据
    async onExport() {
      const params = Object.assign({}, this.search)
      delete params.date
      delete params.pageNo
      delete params.pageSize
      try {
        const { data } = await getDetailDown(params)
        this.$message({
          type: 'success',
          message: data
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 日期选择
    onDateChange(value) {
      if (!value) {
        this.search.startTime = ''
        this.search.endTime = ''
        this.getData()
        return
      }
      const [startTime = '', endTime = ''] = value
      this.search.startTime = startTime
      this.search.endTime = endTime
      this.search.pageNo = 1
      this.getData()
    },
    // 获取撤回明细列表数据
    async getWithdrawData() {
      this.loading = true
      const params = Object.assign({}, this.withdrawSearch)
      delete params.date
      try {
        const res = await this.getAccountBusinessList(params)
        this.loading = false
        this.totalCount = res.data.totalCount
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message.error(error?.message)
      }
    },
    // 撤回明细表格排序
    onWithdrawSortChange(arg) {
      if (arg.prop === 'date') {
        this.withdrawSearch.isAsc = arg.order === 'ascending'
      }
      this.getData()
    },
    onWithdrawChange(page) {
      this.withdrawSearch.pageNo = page
      this.getWithdrawData()
    },
    // 导出数据
    async onWithdrawExport() {
      const params = Object.assign({}, this.withdrawSearch)
      delete params.date
      delete params.pageNo
      delete params.pageSize
      try {
        const { data } = await getDetailDown(params)
        this.$message({
          type: 'success',
          message: data
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 日期选择
    onWithdrawDateChange(value) {
      if (!value) {
        this.withdrawSearch.startTime = ''
        this.withdrawSearch.endTime = ''
        this.getWithdrawData()
        return
      }
      const [startTime = '', endTime = ''] = value
      this.withdrawSearch.startTime = startTime
      this.withdrawSearch.endTime = endTime
      this.withdrawSearch.pageNo = 1
      this.getWithdrawData()
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  padding: 0;
}
::v-deep .financial-table {
  .cell:empty::before {
    content: "/";
    color: gray;
  }
}
::v-deep .holder-color {
  input,
  .el-range-input {
    &::-webkit-input-placeholder {
      color: rgba(96, 98, 102, 0.8);
    }
    &::-moz-placeholder {
      color: #606266;
    }
    &:-ms-input-placeholder {
      color: #606266;
    }
  }
}
::v-deep .el-card__body {
  padding: 0 20px 20px;
}
::v-deep .el-table td:first-child > .cell,
.el-table th:first-child > .cell {
  padding-left: 0;
}
.search {
  .form {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.t-p {
  padding-top: 15px;
}
.plain-button-colored {
  background: #fff;
  border-color: $color-primary;
  color: $color-primary;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
}
</style>
